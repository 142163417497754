import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import config from './../config';

class DownloadPage extends Component {
	constructor(props) {
		super(props);

		this.grecaptchaKey = '6LfIIrUUAAAAACo5o1Xnb22vpu3xjNsH62M-0dj4';
		this.url = config.apiRoot+'/api/nidurhal_umsokn/';

		this.formInputChangeHandler = this.formInputChangeHandler.bind(this);
		this.sendButtonClickHandler = this.sendButtonClickHandler.bind(this);

		this.state = {
			formData: {
				nafn: '',
				netfang: '',
				undirskrift: '',
				samtykkt: false
			},
			grecaptchaResponse: false,
			formSuccess: false
		}
	}

	formInputChangeHandler(event) {
		let value = event.target.type && event.target.type == 'checkbox' ? event.target.checked : event.target.value;

		let formData = this.state.formData;

		formData[event.target.name] = value;

		this.setState({
			formData: formData
		});
	}

	validateForm() {
		let enSite = this.props.lang && this.props.lang == 'en';

		let validationError = [];
		if (this.state.formData.nafn && 
			this.state.formData.netfang &&
			this.validateEmail(this.state.formData.netfang) &&
			this.state.formData.samtykkt
		) {
			this.setState({
				validationError: []
			});

			return true;
		}
		else {
			if (!this.state.formData.nafn || !this.state.formData.nafn) {
				validationError.push(enSite ? 'You have to write your name.' : 'Tú mást skriva navn títt.');
			}
			if (!this.state.formData.netfang || !this.validateEmail(this.state.formData.netfang)) {
				validationError.push(enSite ? 'You have to enter a valid email address.' : 'Tú mást skriva gilda teldupostadressu.');
			}
			if (!this.state.formData.samtykkt || !this.validateEmail(this.state.formData.samtykkt)) {
				validationError.push(enSite ? 'You have to accept the terms.' : 'Tú mást samtykkja treytirnar.');
			}

			this.setState({
				validationError: validationError
			});

			return false;
		}
	}

	prepareData(data) {
		let ret = {};

		for (let key in data) {
			ret[key] = data[key];
		}

		return ret;
	}

	sendButtonClickHandler(event) {
		let enSite = this.props.lang && this.props.lang == 'en';

		event.preventDefault();

		if (this.validateForm()) {
			let formDataValues = new FormData();
			let formData = this.prepareData(this.state.formData);

			for (var key in formData) {
			    formDataValues.append(key, formData[key]);
			}

			formDataValues.append('recaptcha', this.state.grecaptchaResponse);

			fetch(this.url, {
				method: 'post',
				body: formDataValues,
				mode: 'cors'
			})
			.then(function(response) {
				return response.json();
			}.bind(this))
			.then(function(json) {
				if (json.error) {
					this.setState({
						validationError: [this.props.requestErrorText || (enSite ? 'Server error.' : 'Villa í umbøn.')]
					});
				}
				else if (json.success) {
					this.setState({
						validationError: [],
						formSuccess: true
					});

					//this.props.history.push('/mimisbrunnur/'+json.link_id);
				}
			}.bind(this));
		}
	}

	componentDidMount() {
		// Undirbúum grecaptcha
			// Skilgreinum fall sem kallað er á þegar svör berast frá Google reCaptcha API
		window.grecaptchaCallback = this.grecaptchaCallback.bind(this);

		// Frumstillum reCaptcha til að sjá til þess að virknin verði til staðar síðar meir
		this.initializeReCaptcha();
	}

	componentWillUnmount() {
		window.reCaptchaInitialized = false;
	}

	grecaptchaCallback(event) {
		this.setState({
			grecaptchaResponse: event
		});
	}

	initializeReCaptcha() {
		if (window.reCaptchaInitialized) {
			return;
		}

		let grcInit = function() {
			try {
				if (window.grecaptcha) {
					let greCapthcaParams = {
						callback: window.grecaptchaCallback
					};

					if (this.props.lang && this.props.lang == 'en') {
						greCapthcaParams.hl = 'en';
					}

					window.grecaptcha.render('grecaptcha', greCapthcaParams);

					window.reCaptchaInitialized = true;
				}
			}
			catch (e) {
				setTimeout(grcInit, 500);
			}
		}.bind(this);

		grcInit();
	}

	validateEmail(email) {
		var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		return re.test(String(email).toLowerCase());
	}

	render() {
		let enSite = this.props.lang && this.props.lang == 'en';

		return (
			<React.Fragment>
				{
					!this.state.formSuccess &&
					<form className="position-relative">

						<div className="form-group">
							<label htmlFor="nafn">{enSite ? 'Name' : 'Navn'}</label>
							<input type="text" 
								value={this.state.formData.nafn} 
								onChange={this.formInputChangeHandler} 
								className="form-control" 
								name="nafn" 
								id="nafn" />
						</div>

						<div className="form-group">
							<label htmlFor="netfang">{enSite ? 'Email address' : 'Teldupostadressa'}</label>
							<input type="email" 
								value={this.state.formData.netfang} 
								onChange={this.formInputChangeHandler} 
								className="form-control" 
								name="netfang" 
								id="netfang" />
						</div>

						<div className="form-group">
							<label htmlFor="undirskrift">{enSite ? 'On behalf of the rightsholder.' : 'Vegna loyvishava'}</label>
							<input type="email" 
								value={this.state.formData.undirskrift} 
								onChange={this.formInputChangeHandler} 
								className="form-control" 
								name="undirskrift" 
								id="undirskrift" />
							<small>{enSite ? '' : 'Um loyvishavi er ikki einstaklingur'}</small>
						</div>

						<div className="form-check mb-4">
							<label className="form-check-label">
								<input onChange={this.formInputChangeHandler} 
									type="checkbox" 
									className="form-check-input" 
									id="samtykkt" 
									checked={this.state.formData.samtykkt}
									name="samtykkt" /> {enSite ? 'I accept the terms' : 'Eg samtykki treytirnar her omanfyri'}</label>
						</div>

						<div id="grecaptcha" 
							className="g-recaptcha mb-3" 
							data-sitekey={this.grecaptchaKey}></div>

						{
							this.state.validationError && this.state.validationError.length > 0 &&
							<p><strong dangerouslySetInnerHTML={{__html: this.state.validationError.join('<br/>')}}></strong></p>
						}

						<button disabled={!this.state.grecaptchaResponse} type="submit" className="btn btn-primary" onClick={this.sendButtonClickHandler}>{enSite ? 'Request download link' : 'Heinta tilfar'}</button>

					</form>
				}
				{
					this.state.formSuccess &&
					<div className="alert alert-primary">
						{
							enSite ? 
							'Thank you. An email with the download link has been sent to the email address' : 
							'Takk fyri. Leinki fyri at taka niður tilfarið er sendur á teldupostadressuna'
						} <em>{this.state.formData.netfang}</em>.
					</div>
				}
			</React.Fragment>
		);
	}
}

export default withRouter(DownloadPage);
