import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Link, NavLink, Switch, withRouter } from "react-router-dom";

import SearchBox from './components/SearchBox';
import EntryView from './components/EntryView';
import SearchResultsList from './components/SearchResultsList';
import PageMenu from './components/PageMenu';
import PageView from './components/PageView';
import MolarList from './components/MolarList';
import MolarView from './components/MolarView';
import SiteFooter from './components/SiteFooter';
import GoogleAnalytics from './components/GoogleAnalytics';
import DownloadPage from './components/DownloadPage';
import DownloadList from './components/DownloadList';
import createBrowserHistory from 'history/createBrowserHistory';
import config from './config';

import _ from 'underscore';

import logoBendingar from './images/logo-bendingar.svg';
import logoSAM from './images/logo-sam.svg';
import logoSetur from './images/logo-setur.svg';
import logoSmallSAM from './images/logo-sam-notext.svg';
import logoSmallSetur from './images/logo-setur-notext.svg';

const sites = {
	IS: 1,
	EN: 2,
	MALTAEKNI: 3,
	BIN_KJARNI: 8,
	MALTAEKNI_EN: 4,
	BIN_KJARNI_EN: 9
}

class App extends Component {
	constructor(props) {
		super(props);

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);
		this.searchResultsListSearchHandler = this.searchResultsListSearchHandler.bind(this);
		this.loadStartHandler = this.loadStartHandler.bind(this);
		this.loadFinishHandler = this.loadFinishHandler.bind(this);

		window.leit_id = this.resultsLinkLeitIdHandler.bind(this);
		window.leit = this.resultsLinkLeitHandler.bind(this);

		this.state = {
			menuOpen: false,
			searchParams: {
				searchString: '',
				beygingarmynd: false
			},
			routePath: '/',
			loading: false
		};
	}

	componentDidMount() {
		// Hér hlustum við eftir breytingu á route
		this.refs.router.history.listen(function(event) {
			this.setState({
				routePath: event.pathname,
				menuOpen: false
			});

			window.scrollTo(0, 0)
		}.bind(this));

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));
	}

	componentDidUpdate(prevProps) {
	}

	resultsLinkLeitIdHandler(id) {
		this.refs.router.history.push('/bending/'+id);

		return false;
	}

	resultsLinkLeitHandler(search, beygingarmynd) {
		this.refs.router.history.push('/leita/'+(beygingarmynd ? 'bendingarsnid/' : '')+search);

		return false;
	}


	menuButtonClickHandler(event) {
		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	searchResultsListSearchHandler(searchParams) {
		this.setState({
			searchParams: searchParams
		});
	}

	loadStartHandler() {
		this.setState({
			loading: true
		});
	}

	loadFinishHandler() {
		this.setState({
			loading: false
		});
	}

	render() {
		let routePath = this.refs.router && this.refs.router.history.location.pathname != this.state.routePath ? this.refs.router.history.location.pathname : this.state.routePath;

		let isOnContentPage = routePath != '/' &&
			routePath.toLowerCase().substr(0, 9) != '/bending/' &&
			routePath.toLowerCase().substr(0, 6) != '/leita/';

		let site = 1;
		let siteInitials = 'fo';
		let langInitials = 'fo';

		// ekki notað fyrir færeysku

		if (routePath.toLowerCase().substr(0, '/dmii/'.length) == '/dmii/') {
			site = sites.EN;
			siteInitials = 'en';
			langInitials = 'en';
		}
		if (routePath.toLowerCase().substr(0, '/gogn/'.length) == '/gogn/') {
			site = sites.MALTAEKNI;
			siteInitials = 'maltaekni';
			langInitials = 'fo';
		}
		if (routePath.toLowerCase().substr(0, '/DMII/LTdata/'.length) == '/dmii/ltdata/') {
			site = sites.MALTAEKNI_EN;
			siteInitials = 'maltaekni';
			langInitials = 'en';
		}
		if (routePath.toLowerCase().substr(0, '/binkjarni/'.length) == '/binkjarni/') {
			site = sites.BIN_KJARNI;
			siteInitials = 'kjarni';
			langInitials = 'fo';
		}
		if (routePath.toLowerCase().substr(0, '/dmii/dmii-core/'.length) == '/dmii/dmii-core/') {
			site = sites.BIN_KJARNI_EN;
			siteInitials = 'kjarni';
			langInitials = 'en';
		}

		if (!this.refs.router) {
			setTimeout(function() {
				this.forceUpdate();
			}.bind(this), 100);
		}

		return (
			<BrowserRouter ref="router" basename="/">

				<div className={'app-container container-fluid'+
					' site-'+siteInitials+
					' site-lang-'+langInitials+
					(this.state.loading ? ' loading' : '')
				}>

					<div className="header">

						<div className="container">

							<img src={logoSmallSetur} className="float-right ml-2 hidden-mobile-up" />
							<img src={logoSmallSAM} className="float-right hidden-mobile-up" />

							<h1 className="h2">
								<img src={logoBendingar} className="mr-3" alt="" />
								<span>Føroyski bendingargrunnurin</span>
								{/*<span className="hidden-mobile smaller">{'Stofnun Árna Magnússonar í íslenskum fræðum'}</span>*/}
								<img src={logoSetur} className="header-logo float-right ml-4 hidden-mobile" alt="Fróðskaparsetur Føroya" />
								<img src={logoSAM} className="header-logo float-right hidden-mobile logo-sam-big" alt="Stofnun Árna Magnússonar í íslenskum fræðum" lang="is" />
							</h1>

							<PageMenu className="hidden-mobile" />

							<button className="app-menu-button btn-menu hidden-mobile-up" ref="menuButton" onClick={this.menuButtonClickHandler}>
								<div className="hamburger-icon black">
									<div className="icon-middle" />
									<div className="icon-before-after" />
								</div>
							</button>

						</div>

					</div>

					<Route path="/">
						<Switch>

							<Route exact path="/" render={(routeProps) => (
								<React.Fragment>
								<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

							<Route exact path="/forsida/" render={(routeProps) => (
								<React.Fragment>
								<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

							<Route exact path="/DMII/" render={(routeProps) => (
								<React.Fragment>
								<SearchBox lang="en" beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

							<Route exact path="/leita/bendingarsnid/:searchString" render={(routeProps) => (
								<React.Fragment>
								<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

							<Route exact path="/leita/:searchString" render={(routeProps) => (
								<React.Fragment>
								<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

							<Route exact path="/bending/:entryId" render={(routeProps) => (
								<React.Fragment>
								<SearchBox beygingarmynd={this.state.searchParams.beygingarmynd}
								searchString={this.state.searchParams.searchString} />
								</React.Fragment>
							)} />

						</Switch>
					</Route>

					<div className="container">

						<div className="row">

							<div className="col-12 col-md-12 col-lg-12 col-xl-12">

								<div className="column-content">

									<Route exact path="/leita" render={function(routeProps) {
										let queryObj = _.object(_.compact(_.map(routeProps.location.search.slice(1).split('&'), function(item) {  if (item) return item.split('='); })));

										if (queryObj.q) {
											return <Redirect to={'/leita/'+queryObj.q} />
										}
										if (queryObj.id) {
											return <Redirect to={'/bending/'+queryObj.id} />
										}
									}} />

									<Route exact path="/leita/bendingarsnid/:searchString" render={(routeProps) => (
										<React.Fragment>
											<SearchResultsList beygingarmynd={true} onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
										</React.Fragment>
									)} />

									<Route exact path="/leita/:searchString" render={(routeProps) => (
										<React.Fragment>
											<SearchResultsList onSearch={this.searchResultsListSearchHandler} onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
										</React.Fragment>
									)} />

									<Route exact path="/bending/:entryId" render={(routeProps) => (
										<React.Fragment>
											<EntryView onLoadStart={this.loadStartHandler} onLoadFinish={this.loadFinishHandler} {...routeProps} />
										</React.Fragment>
									)} />

									<Route exact path="/*" render={(routeProps) => (
										<React.Fragment>
											<PageView />
											{
												/*
												isOnContentPage &&
												<Link className="floating-search-button" to="/"></Link>
												*/
											}
										</React.Fragment>
									)} />

									<Route exact path="/kornhladan" render={(routeProps) => (
										<MolarList />
									)} />

									<Route exact path="/korn/:kornId" render={(routeProps) => (
										<MolarView {...routeProps} />
									)} />

									<Route exact path="/nidurhal/" render={(routeProps) => (
										<DownloadList />
									)} />

									<Route exact path="/DMII/LTdata/data/" render={(routeProps) => (
										<DownloadPage lang="en" />
									)} />

									<Route exact path="/DMII/LTdata/data/:link_id" render={(routeProps) => (
										<div className="page-content">
											<div className="html-content">
												<h2>Download</h2>
												<DownloadList lang="en" />
											</div>
										</div>
									)} />

								</div>

							</div>
						</div>

						<SiteFooter />


					</div>

					<div className="mobile-header">
						<h1>Føroyski bendingargrunnurin</h1>
					</div>

					<div className={'hidden-mobile-up app-menu'+(this.state.menuOpen ? ' open' : '')} ref="menuContent">
						<div className="container">

							<button className="btn btn-link float-right" onClick={
								function() {
									this.setState({
										menuOpen: false
									});
								}.bind(this)}>
								<div className="hamburger-icon black open">
									<div className="icon-middle" />
									<div className="icon-before-after" />
								</div>
							</button>

							<h5 className="mb-4 pt-2">Bendingar</h5>

							<PageMenu className="hidden-mobile-up" />

						</div>
					</div>

					<GoogleAnalytics />

				</div>

			</BrowserRouter>
		);
	}
}

export default App;
