import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

import config from './../config';
import EntryView from './EntryView';

class PageMenu extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/pages/';

		this.state = {
			listData: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.site && prevProps.site != this.props.site) {
			this.fetchData();
		}
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: []
		});

		fetch(this.url+'?site='+(this.props.site || 1))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results
				});
			}.bind(this));
	}

	render() {
		return (
			<React.Fragment>
				<div className={'menu-links'+(this.props.className ? ' '+this.props.className : '')}>
					{
						this.state.listData.length > 0 &&
						this.state.listData.map(function(item, index) {
							return <NavLink key={index} exact to={item.url} className={item.menu_separator ? ' separator' : ''}>{item.title}</NavLink>;
						}.bind(this))
					}

				</div>
				{/*
				{
					(this.props.site == 1) &&
					<ul>
						<li className="separator"><Link to="/DMII/">English</Link></li>
					</ul>
				}
				{
					(this.props.site == 2) &&
					<ul>
						<li className="separator"><Link to="/">Íslenska</Link></li>
					</ul>
				}
				{
					(this.props.site == 3) && // Máltækni
					<ul>
						<li className="separator"><Link to="/DMII/LTData/">English</Link></li>
					</ul>
				}
				{
					(this.props.site == 4) && // Máltækni enska
					<ul>
						<li className="separator"><Link to="/gogn/">Íslenska</Link></li>
					</ul>
				}
				{
					(this.props.site == 8) && // BÍN-kjarni
					<ul>
						<li className="separator"><Link to="/DMII/dmii-core/">English</Link></li>
					</ul>
				}
				{
					(this.props.site == 9) && // BÍN-kjarni enska
					<ul>
						<li className="separator"><Link to="/binkjarni/">Íslenska</Link></li>
					</ul>
				}
				*/}
			</React.Fragment>
		);
	}
}

export default withRouter(PageMenu);
