import React, { Component } from 'react';
import { Link } from "react-router-dom";

import config from './../config';

class SearchResultsList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			resultsHtml: null,
			fetching: true,
			initialized: false
		};

	}

	componentDidMount() {
		if (this.props.match.params.searchString) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log(this.props)
		if (this.props.match.params.searchString && 
			(
				prevProps.match.params.searchString != this.props.match.params.searchString
			)
		) {
			this.fetchData();
		}
	}

	fetchData() {
		window.scrollTo(0, 0);


		if (this.props.onLoadStart) {
			this.props.onLoadStart();
		}

		// Sæki gögn til APA
		this.setState({
			resultsHtml: null,
			fetching: true,
			initialized: false
		});

		let urlParams = [];
		if (this.props.match.params.searchString) {
			urlParams.push('q='+this.props.match.params.searchString);

			window.document.title = config.siteTitle+' | '+this.props.match.params.searchString;
		}

		if (this.props.beygingarmynd) {
			urlParams.push('ordmyndir=on');
		}

		fetch(config.binPhpRoot+'?'+urlParams.join('&'))
			.then(function(response) {
				return response.text();
			})
			.then(function(text) {
				this.setState({
					resultsHtml: text,
					fetching: false
				})

				setTimeout(function() {
					this.setState({
						initialized: true
					});
				}.bind(this), 200);

				if (this.props.onLoadFinish) {
					this.props.onLoadFinish();
				}
			}.bind(this));

		if (this.props.onSearch) {
			this.props.onSearch(this.props.match.params);
		}

		if (this.props.onSearch) {
			this.props.onSearch({
				searchString: this.props.match.params.searchString,
				beygingarmynd: this.props.beygingarmynd
			});
		}
	}

	formatHtml(html) {
		return html.replace(/href="#"/g, '');
	}

	render() {
		return (
			<div>
				{
					this.state.resultsHtml &&
					<div dangerouslySetInnerHTML={{__html: this.formatHtml(this.state.resultsHtml)}} />
				}
			</div>
		);
	}
}

export default SearchResultsList;
