import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import config from './../config';

import ToggleSwitch from './ToggleSwitch';
import StickyWatcher from './StickyWatcher';

import searchIcon from './../images/icon-search.svg';

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.inputChangeHandler = this.inputChangeHandler.bind(this);
		this.inputKeyDownHandler = this.inputKeyDownHandler.bind(this);
		this.executeSearch = this.executeSearch.bind(this);
		this.inputFocusHandler = this.inputFocusHandler.bind(this);

		this.state = {
			searchBoxInput: '',
			beygingarmyndInput: false
		};
	}

	componentDidMount() {
		var stateObj = this.state;

		if (this.props.searchString) {
			stateObj.searchBoxInput = this.props.searchString;
		}
		if (this.props.beygingarmynd) {
			stateObj.beygingarmyndInput = this.props.beygingarmynd;
		}

		this.setState(stateObj);

		this.refs.searchInput.focus();

		setTimeout(function() {
			this.inputFocusHandler();
		}.bind(this), 100);
	}

	componentDidUpdate(prevProps, prevState) {
		var stateObj = this.state;

		var stateChanged = false;

		if (this.props.searchString != prevProps.searchString) {
			stateObj.searchBoxInput =this.props.searchString;

			stateChanged = true;
		}

		if (this.props.beygingarmynd != prevProps.beygingarmynd) {
			stateObj.beygingarmyndInput =this.props.beygingarmynd;

			stateChanged = true;
		}

		if (stateChanged) {
			this.setState(stateObj);
		}
	}

	inputChangeHandler(event) {
		let value = event.target.type && event.target.type == 'checkbox' ? event.target.checked : event.target.value;

		var stateObj = {};

		stateObj[event.target.name] = value;

		this.setState(stateObj);
	}

	inputKeyDownHandler(event) {
		if (event.keyCode == 13) { // enter
			this.executeSearch();
		}
	}

	inputFocusHandler() {
		this.refs.searchInput.select();
	}

	executeSearch() {
		if (this.state.searchBoxInput != '') {
			this.props.history.push('/leita/'+(this.state.beygingarmyndInput ? 'bendingarsnid/' : '')+this.state.searchBoxInput);
		}
	}

	render() {
		let enSite = this.props.lang && this.props.lang == 'en';

		let specialChars = ['á', 'ð', 'é', 'í', 'ó', 'ú', 'ý', 'æ', 'ø'];

		let specialCharLinks = specialChars.map(function(char) {
			return <a key={char} onClick={function(event) {
				event.preventDefault();

				this.setState({
					searchBoxInput: this.state.searchBoxInput+char
				});
			}.bind(this)}>{char}</a>
		}.bind(this));

		return (
			<React.Fragment>
				<StickyWatcher className={'search-form'} stickyClassName="sticky" useBodyTag={true}>

					<div className="container">
						<div className="form-group column-content">

							<div className="d-flex">
								<div className=" flex-grow-1 mr-2 mr-sm-4">
									<input className="form-control malid-style"
										ref="searchInput"
										name="searchBoxInput"
										value={this.state.searchBoxInput}
										type="text"
										onChange={this.inputChangeHandler}
										onKeyDown={this.inputKeyDownHandler}
										onFocus={this.inputFocusHandler}
										placeholder={enSite ? 'Enter search word' : 'Leita í Bendingargrunninum, t.d. aðal* ella ma_ur'}
									/>
								</div>

								<button onClick={this.executeSearch.bind(this)} className="btn btn-primary">
									<span className="label">{enSite ? 'Search' : 'Leita'}</span>
									<span className="icon"><img src={searchIcon} alt="" /></span>
								</button>
							</div>

							<div className="search-info">
								{
									enSite &&
									<span className="d-none d-sm-inline mr-4">The search results appear on the Icelandic site</span>
								}
								{
									<span className="d-none d-sm-inline">{specialCharLinks}</span>
								}
							</div>

							<div className="form-group form-check">
								<ToggleSwitch label={enSite ? 'Search using inflectional form' : 'Leita við bendingarsniði'} onChange={this.inputChangeHandler} name="beygingarmyndInput" value={this.state.beygingarmyndInput} />
								{/*<input type="checkbox"
									onChange={this.inputChangeHandler}
									className="form-check-input"
									checked={this.state.beygingarmyndInput}
									name="beygingarmyndInput"
									id="beygingarmyndInput" />
								<label className="form-check-label" htmlFor="beygingarmyndInput">Leita að beygingarmynd</label>*/}
							</div>

							<div className="clearfix" />

						</div>
					</div>

					<div className="loading-bar">
						<div className="indicator" />
					</div>

				</StickyWatcher>
			</React.Fragment>
		);
	}
}

export default withRouter(SearchBox);
