import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import config from './../config';

class MolarView extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/molar/';

		this.htmlContentClickHandler = this.htmlContentClickHandler.bind(this);
		this.expandButtonClickHandler = this.expandButtonClickHandler.bind(this);

		this.state = {
			initialized: false,
			expanded: false
		};
	}

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				data: this.props.data
			});
		}
		else {
			this.fetchData();
		}
	}

	isExternal(url) {
		var checkDomain = function(url) {
			if ( url.indexOf('//') === 0 ) { url = window.location.protocol + url; }
			return url.toLowerCase().replace(/([a-z])?:\/\//,'$1').split('/')[0];
		};

		return ( ( url.indexOf(':') > -1 || url.indexOf('//') > -1 ) && checkDomain(window.location.href) !== checkDomain(url) );
	}

	htmlContentClickHandler(event) {
		let linkEl = event.target.closest('a');

		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');
			if (!this.isExternal(linkUrl)) {
				event.preventDefault();
				this.props.history.push(linkUrl);
			}
		}
	}

	expandButtonClickHandler(event) {
		event.preventDefault();

		this.setState({
			expanded: !this.state.expanded
		});
	}

	stripHtml(html) {
		var tmp = document.createElement("DIV");
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	}

	fetchData() {
		// Sæki gögn til APA
		fetch(this.url+(this.props.match && this.props.match.params.kornId ? this.props.match.params.kornId : '?limit=1&order=random'))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: (this.props.match && this.props.match.params.kornId ? json : json.results[0])
				});

				if (json.titill) {
					window.document.title = config.siteTitle+' | '+this.stripHtml(json.titill);
				}

				setTimeout(function() {
					this.setState({
						initialized: true
					});
				}.bind(this), 200);
			}.bind(this));
	}

	formatHtml(html) {
		let formatedHtml = html.replace(/\\r|\\n/g, '');

		return formatedHtml;
	}

	render() {
		let dataItem = this.state.data || null;

		let HeadingTag = this.props.match && this.props.match.params.kornId ? 'h2' : 'h4';

		return (
			<React.Fragment>
				{
					dataItem &&
					<div className={'expandable-box'+(this.state.expanded ? ' expanded' : '')+(this.props.match && this.props.match.params.kornId ? ' page-content' : '')}>
						{
							this.props.showTitle &&
							<React.Fragment>
								<h3 className="float-left">Korn</h3>
								<Link to="/kornhladan/" className="float-right">Kornhlaðan</Link>
								<div className="clearfix" />
							</React.Fragment>
						}
						<HeadingTag dangerouslySetInnerHTML={{__html: dataItem.titill}} />
						<p dangerouslySetInnerHTML={{__html: this.formatHtml(dataItem.content)}} />

						{
							this.props.listView &&
							<Link className="expand-button" to={'/korn/'+dataItem.id}>Lesa meira</Link>
						}
						{
							!this.props.listView && !(this.props.match && this.props.match.params.kornId) &&
							<a className="expand-button" href="#" onClick={this.expandButtonClickHandler}>Lesa meira</a>
						}
					</div>
				}
			</React.Fragment>
		);
	}
}

export default withRouter(MolarView);
