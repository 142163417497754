import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MolarView from './MolarView';
import config from './../config';

class MolarList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/molar/';

		this.state = {};

	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		window.scrollTo(0, 0);

		// Sæki gögn til APA
		this.setState({
			resultsHtml: null,
			fetching: true,
			initialized: false
		});

		fetch(this.url)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: json.results
				})
			}.bind(this));
	}

	formatHtml(html) {
		return html.replace(/href="#"/g, '');
	}

	render() {
		return (
			<div className="page-content">

				<div className="row front-banner">
					{
						this.state.data && this.state.data.length > 0 &&
						this.state.data.map(function(item, index) {
							return <div className="col col-12 mb-4" key={index}>
								<div className="column-content">
									<MolarView listView={true} data={item} />
								</div>
							</div>;
						})
					}
				</div>
			</div>
		);
	}
}

export default MolarList;
